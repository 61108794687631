@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto:wght@500&display=swap);
/* Global styles and variables */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #F9CC0B;
  --secondary-color: #F9F9F9;
  --third-color: #0C1D36;
  --primary-text-color: #0C1D36;
  --secondary-text-color: #577592;
  --accent-color: #2294ed;
  --accent-color-dark: #F9F9F9;
}

body {
  font-family: "Poppins", sans-serif;
  color: var(--primary-text-color);
  background-color: var(--secondary-color);
  margin: 0;
  padding: 0;
}

p {
  font-family: "Roboto", sans-serif;
  color: var(--secondary-text-color);
  line-height: 1.4rem;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.flex {
  display: flex;
  align-items: center;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

nav {
  background-color: var(--third-color);
  box-shadow: 0 0 35px #bbd0e2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  text-align: center; /* Center the content inside the nav */
}

.main-nav {
  justify-content: space-between;
  padding: 16px 0;
}

.company-logo img {
  width: 100px;
  height: auto;
}

.nav-toggle {
  display: none;
  cursor: pointer;
}

.nav-links {
  display: flex;
  align-items: center;
  grid-gap: 16px;
  gap: 16px;
  font-family: "Roboto", sans-serif;
  font-size: 14.33px;
}

.hover-link {
  cursor: pointer;
  transition: color 0.3s;
}

.hover-link:hover {
  color: var(--primary-color);
}

.search-bar {
  display: flex;
  align-items: center;
  margin-left: 16px;
  margin-right: 5px;
}

.news-input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  width: 250px;
  margin-right: 8px;
}

/* Existing CSS styles here */

/* Existing CSS styles here */

.company-search {
  background-color: var(--primary-color) !important;
  color: var(--third-color) !important;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.company-search:hover {
  background-color: var(--secondary-color) !important;
  /* color: var(--accent-color-dark) !important; */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

/* Popup Styles */
.MuiDialog-root .MuiPaper-root {
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.MuiDialogTitle-root {
  font-size: 18px;
  font-weight: 600;
  color: var(--third-color);
}

.MuiDialogContent-root {
  padding: 16px;
}

.MuiDialogActions-root {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.MuiButton-root {
  font-weight: 500;
}

.MuiButton-containedPrimary {
  background-color: var(--primary-color);
  color: var(--third-color);
}

.MuiButton-containedPrimary:hover {
  background-color: var(--accent-color-dark);
  color: #000000;
}

  
.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  gap: 24px;
  margin-top: 100px; /* Adjust to prevent overlap with fixed nav */
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  height: 100%; /* Ensure all cards are the same height */
  }

.card:hover {
  transform: translateY(-10px);
}

.card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Ensure the content section grows to fill available space */
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.blog-meta p {
  margin: 0;
  font-size: 12px;
}

.news-desc {
  font-size: 0.9rem;
  line-height: 1.5rem;
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  flex-grow: 1; /* Ensure the description grows to fill available space */
}

footer {
  background-color: var(--third-color);
  padding: 20px 0;
  text-align: center;
  color: var(--secondary-color);
  margin-top: 20px;
}

.footer p {
  margin: 0;
}
.footer-text p{
  color: var(--secondary-color);
}

.footer-text p {
  color: var(--accent-color-dark);
}

.back-to-top-floating {
  position: fixed;
  bottom: 65px;
  right: 20px;
  width: 50px; /* Adjust size */
  height: 50px; /* Adjust size */
  background-color: var(--primary-color);
  color: var(--primary-color);
  padding: 12px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%; /* Circular button */
  transition: background-color 0.3s ease;
}

.back-to-top-floating:hover {
  background-color: var(--third-color); /* Change background on hover */
}

.back-to-top-floating i {
  font-size: 28px; /* Increase icon size */
  color: var(--third-color); /* Icon color */
  transition: color 0.3s ease; /* Smooth color transition */
}

.back-to-top-floating:hover i {
  color: var(--primary-color); /* Icon color change on hover */
}



@media screen and (min-width: 480px) {
  .company-logo img {
    width: 100px;
    margin-left: 5px;
    height: auto;
  }
}

@media screen and (min-width: 128px) and (max-width: 480px) {
  .company-logo img {
    width: 100px;
    margin-left: 5px;
    height: auto;
  }
  .search-bar {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 16px;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    height: 100%;
    margin: 0px 20px 0px 20px;
}
}
@media screen and (min-width: 480px) and (max-width: 800px) {
  .company-logo img {
    width: 100px;
    margin-left: 5px;
    height: auto;
  }
  .search-bar {
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-left: 16px;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.25);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    height: 100%;
    margin: 0px 20px 0px 20px;
}
}


